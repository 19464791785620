import * as SystemUI from 'expo-system-ui';
import React, { useEffect, useMemo } from 'react';
import { Platform, useColorScheme, } from 'react-native';
export const DefaultTheme = {
    colors: {
        accent: '#03dac4',
        backdrop: '#00000088',
        background: '#f6f6f6',
        disabled: '#00000044',
        error: '#B00020',
        notification: '#f50057',
        onSurface: '#000000',
        placeholder: '#00000099',
        primary: '#6200ee',
        surface: 'white',
        text: 'black',
    },
};
export const ThemeContext = React.createContext({ theme: DefaultTheme });
export const ThemeProvider = ({ children, theme, darkTheme, }) => {
    const colorScheme = useColorScheme();
    useEffect(() => {
        if (Platform.OS === 'android') {
            void SystemUI.setBackgroundColorAsync(theme.colors.background);
        }
    }, [colorScheme, theme]);
    const value = useMemo(() => ({
        theme: colorScheme === 'dark' ? darkTheme || theme : theme,
    }), [colorScheme, darkTheme, theme]);
    return (React.createElement(ThemeContext.Provider, { value: value }, children));
};
export default { ThemeProvider };
