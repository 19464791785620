import React, { useMemo, createContext, useContext, useState, } from 'react';
import { Provider } from 'urql';
import { AuthContext } from './Auth';
const DEFAULT_VALUE = {
    reloadClient: () => { },
};
export const UrqlContext = createContext(DEFAULT_VALUE);
function UrqlProvider({ children, createClient, onError }) {
    const { token, clearToken } = useContext(AuthContext);
    const [reloadClientAt, setReloadClientAt] = useState(Date.now());
    const client = useMemo(() => createClient({ token, clearToken, onError }), [
        token, clearToken, onError, createClient, reloadClientAt,
    ]);
    const value = useMemo(() => ({
        reloadClient: () => setReloadClientAt(Date.now()),
    }), []);
    return (React.createElement(UrqlContext.Provider, { value: value },
        React.createElement(Provider, { value: client }, children)));
}
export default UrqlProvider;
