import { Dimensions } from 'react-native';
import { create } from 'zustand';
function calculateInset(allCustomInsets, defaultInsets) {
    const lastInset = allCustomInsets[allCustomInsets.length - 1];
    return lastInset ? Object.assign(Object.assign({}, defaultInsets), lastInset) : defaultInsets;
}
export const useSharedPortalAreaStore = create((set, get) => ({
    allCustomInsets: [],
    defaultInsets: {
        top: 0, bottom: 0, left: 0, right: 0,
    },
    setDefaultInsets: (defaultInsets) => set(() => ({
        defaultInsets,
        insets: calculateInset(get().allCustomInsets, defaultInsets),
    })),
    insets: {
        top: 0, bottom: 0, left: 0, right: 0,
    },
    size: {
        x: 0,
        y: 0,
        width: Dimensions.get('window').width,
        height: 0,
    },
    pushInset: (insets) => set((state) => {
        const allCustomInsets = [...state.allCustomInsets, insets];
        return {
            allCustomInsets,
            insets: calculateInset(allCustomInsets, state.defaultInsets),
        };
    }),
    removeInset: (id) => set((state) => {
        const allCustomInsets = state.allCustomInsets.filter(({ id: prevId }) => prevId !== id);
        return {
            allCustomInsets,
            insets: calculateInset(allCustomInsets, state.defaultInsets),
        };
    }),
    setSize: (size) => set(() => ({ size })),
}));
export default useSharedPortalAreaStore;
