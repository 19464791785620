var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from 'react';
import { Text as NativeText, StyleSheet, useWindowDimensions, View, } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useTheme from '../hooks/useTheme';
export function createThemedStylesHook(factory) {
    return (props) => {
        const theme = useTheme(), size = useWindowDimensions(), insets = useSafeAreaInsets();
        return useMemo(() => StyleSheet.create(factory(Object.assign({ insets, size, theme }, props))), [
            theme, size, insets, props,
        ]);
    };
}
export function createThemedView(factory) {
    const ThemedComponent = (_a) => {
        var { children } = _a, props = __rest(_a, ["children"]);
        const theme = useTheme(), size = useWindowDimensions(), insets = useSafeAreaInsets(), style = useMemo(() => factory(Object.assign({ insets, size, theme }, props)), [
            theme, size, insets, props,
        ]);
        return React.createElement(View, Object.assign({}, props, { style: style }), children);
    };
    return React.memo(ThemedComponent);
}
export function createThemedText(factory) {
    const ThemedComponent = (_a) => {
        var { children } = _a, props = __rest(_a, ["children"]);
        const theme = useTheme(), size = useWindowDimensions(), insets = useSafeAreaInsets(), style = useMemo(() => factory(Object.assign({ insets, size, theme }, props)), [
            theme, size, insets, props,
        ]);
        return React.createElement(NativeText, Object.assign({}, props, { style: style }), children);
    };
    return React.memo(ThemedComponent);
}
export default createThemedStylesHook;
