import { useEffect } from 'react';
import useSnackbarStore from './useSnackbarStore';
export const useSnackbarSettings = (settings) => {
    const setDefaultTimeoutMs = useSnackbarStore((state) => state.setDefaultTimeoutMs);
    const setSnackbarsToShowAtSameTime = useSnackbarStore((state) => state.setSnackbarsToShowAtSameTime);
    useEffect(() => {
        if (settings.defaultTimeoutMs != null)
            setDefaultTimeoutMs(settings.defaultTimeoutMs);
    }, [setDefaultTimeoutMs, settings.defaultTimeoutMs]);
    useEffect(() => {
        if (settings.snackbarsToShowAtSameTime != null)
            setSnackbarsToShowAtSameTime(settings.snackbarsToShowAtSameTime);
    }, [setSnackbarsToShowAtSameTime, settings.snackbarsToShowAtSameTime]);
};
export default useSnackbarSettings;
