const Styles = {
    alignItemsCenter: { alignItems: 'center' },
    alignSelfCenter: { alignSelf: 'center' },
    bold: { fontWeight: 'bold' },
    borderBottomRadius16: { borderBottomLRightadius: 16, borderBottomLeftRadius: 16 },
    borderBottomRadius32: { borderBottomLRightadius: 32, borderBottomLeftRadius: 32 },
    borderBottomRadius4: { borderBottomLeftRadius: 4, borderBottomRightRadius: 4 },
    borderBottomRadius8: { borderBottomLeftRadius: 8, borderBottomRightRadius: 8 },
    borderRadius16: { borderRadius: 16 },
    borderRadius32: { borderRadius: 32 },
    borderRadius4: { borderRadius: 4 },
    borderRadius8: { borderRadius: 8 },
    borderTopRadius16: { borderTopLRightadius: 16, borderTopLeftRadius: 16 },
    borderTopRadius32: { borderTopLRightadius: 32, borderTopLeftRadius: 32 },
    borderTopRadius4: { borderTopLeftRadius: 4, borderTopRightRadius: 4 },
    borderTopRadius8: { borderTopLeftRadius: 8, borderTopRightRadius: 8 },
    flexOne: { flex: 1 },
    flexRow: { flexDirection: 'row' },
    justifyContentCenter: { justifyContent: 'center' },
    margin16: { margin: 16 },
    margin4: { margin: 4 },
    margin8: { margin: 8 },
    marginBottom16: { marginBottom: 16 },
    marginBottom4: { marginBottom: 4 },
    marginBottom8: { marginBottom: 8 },
    marginHorizontal16: { marginHorizontal: 16 },
    marginHorizontal4: { marginHorizontal: 4 },
    marginHorizontal8: { marginHorizontal: 8 },
    marginLeft16: { marginLeft: 16 },
    marginLeft4: { marginLeft: 4 },
    marginLeft8: { marginLeft: 8 },
    marginRight16: { marginRight: 16 },
    marginRight4: { marginRight: 4 },
    marginRight8: { marginRight: 8 },
    marginTop16: { marginTop: 16 },
    marginTop4: { marginTop: 4 },
    marginTop8: { marginTop: 8 },
    marginVertical16: { marginVertical: 16 },
    marginVertical4: { marginVertical: 4 },
    marginVertical8: { marginVertical: 8 },
    padding16: { padding: 16 },
    padding4: { padding: 4 },
    padding8: { padding: 8 },
    paddingBottom16: { paddingBottom: 16 },
    paddingBottom4: { paddingBottom: 4 },
    paddingBottom8: { paddingBottom: 8 },
    paddingHorizontal16: { paddingHorizontal: 16 },
    paddingHorizontal4: { paddingHorizontal: 4 },
    paddingHorizontal8: { paddingHorizontal: 8 },
    paddingLeft16: { paddingLeft: 16 },
    paddingLeft4: { paddingLeft: 4 },
    paddingLeft8: { paddingLeft: 8 },
    paddingRight16: { paddingRight: 16 },
    paddingRight4: { paddingRight: 4 },
    paddingRight8: { paddingRight: 8 },
    paddingTop16: { paddingTop: 16 },
    paddingTop4: { paddingTop: 4 },
    paddingTop8: { paddingTop: 8 },
    paddingVertical16: { paddingVertical: 16 },
    paddingVertical4: { paddingVertical: 4 },
    paddingVertical8: { paddingVertical: 8 },
    shadowLight: {
        elevation: 1,
        shadowColor: 'black',
        shadowOffset: { height: 0, width: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
    },
    textCenter: { textAlign: 'center' },
    textLeft: { textAlign: 'left' },
    width100pct: { width: '100%' },
};
export default Styles;
