var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useContext, useEffect, useMemo, useState, } from 'react';
import { ActivityIndicator, Button, StyleSheet, View, } from 'react-native';
import { match } from 'ts-pattern';
import StringsContext from '../contexts/Strings';
import useEvent from '../hooks/useEvent';
import useIsOnline from '../hooks/useIsOnline';
import { Text } from '../primitives';
const styles = StyleSheet.create({
    activityIndicator: { marginTop: 15 },
    container: {
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    text: { paddingBottom: 10, textAlign: 'center' },
    icon: { padding: 20 },
});
export const GenericError = ({ onTryAgain, onTryAgainWithNetworkStatus, customMessage, error, }) => {
    const [loading, setLoading] = useState(false), isOnline = useIsOnline(), strings = useContext(StringsContext), [hasBeenOffline, setHasBeenOffline] = useState(false);
    useEffect(() => {
        if (!isOnline && isOnline !== null) {
            setHasBeenOffline(true);
        }
    }, [isOnline]);
    const doTryAgain = useEvent(() => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            yield (onTryAgain === null || onTryAgain === void 0 ? void 0 : onTryAgain());
            yield (onTryAgainWithNetworkStatus === null || onTryAgainWithNetworkStatus === void 0 ? void 0 : onTryAgainWithNetworkStatus(!hasBeenOffline && !(error === null || error === void 0 ? void 0 : error.networkError)));
        }
        finally {
            setLoading(false);
        }
    }));
    const message = useMemo(() => (customMessage || match({ error, hasBeenOffline })
        .with({ hasBeenOffline: true }, () => strings['You are offline'])
        .when(({ error }) => !!(error === null || error === void 0 ? void 0 : error.networkError), () => strings['Network request failed'])
        .otherwise(() => strings['Something went wrong, please try again'])), [
        customMessage, error, hasBeenOffline, strings,
    ]);
    return (React.createElement(View, { style: styles.container },
        React.createElement(MaterialCommunityIcons, { name: !hasBeenOffline
                ? 'alert'
                : 'lightning-bolt-circle', style: styles.icon }),
        React.createElement(Text, { style: styles.text }, message),
        onTryAgain
            ? React.createElement(Button, { onPress: doTryAgain, title: strings['Try again'] })
            : null,
        loading
            ? React.createElement(ActivityIndicator, { style: styles.activityIndicator })
            : null));
};
export default GenericError;
