import { Portal } from '@gorhom/portal';
import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import randomHexColorAlpha from '../utils/randomHexColor';
export const NativePortal = ({ children, pointerEvents = 'box-none', insets, colorize, }) => {
    const style = useMemo(() => [
        StyleSheet.absoluteFill,
        { justifyContent: 'flex-end' },
        insets,
        { backgroundColor: colorize ? randomHexColorAlpha() : undefined },
    ], [insets, colorize]);
    return (React.createElement(Portal, null,
        React.createElement(View, { pointerEvents: pointerEvents, style: style }, children)));
};
export default NativePortal;
