var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import NetInfo from '@react-native-community/netinfo';
import { useState, useEffect } from 'react';
import useRunIfMounted from './useRunIfMounted';
export const useIsOnline = () => {
    const [isOnline, setIsOnline] = useState(false), runIfMounted = useRunIfMounted();
    useEffect(() => {
        const updateNetwork = (networkState) => {
            runIfMounted(() => setIsOnline((networkState.isConnected && networkState.isInternetReachable !== false) || false));
        };
        const endNetworkSubscription = NetInfo.addEventListener(updateNetwork);
        const init = () => __awaiter(void 0, void 0, void 0, function* () {
            const networkState = yield NetInfo.fetch();
            updateNetwork(networkState);
        });
        void init();
        return () => {
            endNetworkSubscription();
        };
    }, [runIfMounted]);
    return isOnline;
};
export default useIsOnline;
