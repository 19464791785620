import React, { useCallback } from 'react';
import Animated, { CurvedTransition } from 'react-native-reanimated';
import useSharedPortalAreaStore from '../hooks/useSharedPortalAreaStore';
import NativePortal from './NativePortal';
export const SharedPortalPresentationArea = ({ children, style, colorize, }) => {
    const insets = useSharedPortalAreaStore((state) => state.insets);
    const setSize = useSharedPortalAreaStore((state) => state.setSize);
    const onLayout = useCallback((event) => {
        setSize(event.nativeEvent.layout);
    }, [setSize]);
    return (React.createElement(NativePortal, { insets: insets, colorize: colorize },
        React.createElement(Animated.View, { layout: CurvedTransition.duration(500), onLayout: onLayout, style: style, pointerEvents: 'box-none' }, children)));
};
export default SharedPortalPresentationArea;
