var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useMemo, useState, createContext, useContext, } from 'react';
export var Status;
(function (Status) {
    Status[Status["INITIALIZING"] = 0] = "INITIALIZING";
    Status[Status["READY_WITH_TOKEN"] = 1] = "READY_WITH_TOKEN";
    Status[Status["READY_WITHOUT_TOKEN"] = 2] = "READY_WITHOUT_TOKEN";
})(Status || (Status = {}));
export const AuthContext = createContext({
    clearToken: () => { },
    hasToken: false,
    setToken: () => { },
    token: null,
    status: Status.INITIALIZING,
});
const AUTH_TOKEN_KEY_DEFAULT = 'AUTH_TOKEN';
export const getToken = (authTokenKey = AUTH_TOKEN_KEY_DEFAULT) => __awaiter(void 0, void 0, void 0, function* () {
    const t = yield AsyncStorage.getItem(authTokenKey);
    return t;
});
export function getDecodedToken() {
    return __awaiter(this, void 0, void 0, function* () {
        const t = yield getToken();
        return t ? jwtDecode(t) : null;
    });
}
const AuthProvider = ({ children, authTokenKey = AUTH_TOKEN_KEY_DEFAULT }) => {
    const [{ token, isReady }, setToken] = useState({ token: null, isReady: false });
    useEffect(() => {
        const init = () => __awaiter(void 0, void 0, void 0, function* () {
            const t = yield getToken();
            setToken({ token: t, isReady: true });
        });
        void init();
    }, []);
    const value = useMemo(() => ({
        clearToken: () => {
            setToken({ token: null, isReady: true });
            void AsyncStorage.removeItem(authTokenKey);
        },
        hasToken: !!token,
        setToken: (t) => {
            setToken({ token: t, isReady: true });
            void AsyncStorage.setItem(authTokenKey, t);
        },
        token,
        status: isReady ? (token ? Status.READY_WITH_TOKEN : Status.READY_WITHOUT_TOKEN) : Status.INITIALIZING,
    }), [token, isReady, authTokenKey]);
    return (React.createElement(AuthContext.Provider, { value: value }, children));
};
export const useToken = () => {
    const { token } = useContext(AuthContext);
    return token;
};
export const useStatus = () => {
    const { status } = useContext(AuthContext);
    return status;
};
export const useSetToken = () => {
    const { setToken } = useContext(AuthContext);
    return setToken;
};
export const useClearToken = () => {
    const { clearToken } = useContext(AuthContext);
    return clearToken;
};
export default AuthProvider;
