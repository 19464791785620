import { useCallback, useEffect, useRef } from 'react';
export const useRunIfMounted = () => {
    const mountedRef = useRef(false);
    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);
    const runIfMounted = useCallback((cb) => {
        if (mountedRef.current) {
            cb();
        }
    }, []);
    return runIfMounted;
};
export default useRunIfMounted;
