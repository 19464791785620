var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from 'react';
import { Platform, StyleSheet, Text, TouchableOpacity, View, } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
const DEFAULT_BACKGROUND_COLOR = '#323232', DEFAULT_BUTTON_TEXT_COLOR = '#B28FF0', DEFAULT_TEXT_COLOR = '#CDCDCD';
export const styles = StyleSheet.create({
    snackbarContent: {
        marginBottom: Platform.OS === 'web' ? 0 : 16,
    },
    buttonText: {
        color: DEFAULT_BUTTON_TEXT_COLOR,
        fontWeight: '500',
        textAlign: 'right',
        textTransform: 'uppercase',
    },
    nonFirstButton: {
        marginLeft: 16,
    },
    snackbar: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        padding: 16,
        borderRadius: 5,
        margin: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    snackbarText: {
        color: DEFAULT_TEXT_COLOR,
    },
    snackbarButtonWrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexGrow: 1,
        marginBottom: Platform.OS === 'web' ? 0 : 16,
        alignItems: 'center',
    },
});
const DEFAULT_ANIMATION_DURATION = 500;
export const DEFAULT_ENTERING = FadeIn.duration(DEFAULT_ANIMATION_DURATION);
export const DEFAULT_EXITING = FadeOut.duration(DEFAULT_ANIMATION_DURATION);
export const DefaultSnackbarComponent = React.memo((_a) => {
    var { snackbarConfig, textColor, textStyle } = _a, wrapperProps = __rest(_a, ["snackbarConfig", "textColor", "textStyle"]);
    return (React.createElement(DefaultSnackbarWrapper, Object.assign({}, wrapperProps, { snackbarConfig: snackbarConfig }),
        React.createElement(Text, { style: [styles.snackbarText, textStyle, textColor ? { color: textColor } : null] }, snackbarConfig.title)));
});
export const DefaultSnackbarWrapper = React.memo(({ snackbarConfig, doDismiss, backgroundColor, buttonColor, buttonTextStyle, id, style, entering, exiting, children, contentStyle, }) => {
    var _a;
    const renderButton = useCallback((a, index) => (React.createElement(TouchableOpacity, { accessibilityRole: 'button', key: a.key || a.label, onPress: () => {
            var _a;
            doDismiss(id);
            (_a = a.onPress) === null || _a === void 0 ? void 0 : _a.call(a, a);
        } },
        React.createElement(Text, { style: [
                styles.buttonText,
                buttonTextStyle,
                buttonColor ? { color: buttonColor } : null,
                index === 0 ? null : styles.nonFirstButton,
            ] }, a.label))), [
        buttonColor, buttonTextStyle, doDismiss, id,
    ]);
    return (React.createElement(Animated.View, { entering: entering !== null && entering !== void 0 ? entering : DEFAULT_ENTERING, exiting: exiting !== null && exiting !== void 0 ? exiting : DEFAULT_EXITING },
        React.createElement(View, { style: [styles.snackbar, style, backgroundColor ? { backgroundColor } : null] },
            React.createElement(View, { style: [styles.snackbarContent, contentStyle] }, children),
            React.createElement(View, { style: styles.snackbarButtonWrapper }, (_a = snackbarConfig.actions) === null || _a === void 0 ? void 0 : _a.map(renderButton)))));
});
export default DefaultSnackbarComponent;
