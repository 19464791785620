import React, { useEffect } from 'react';
import { View } from 'react-native';
import useRemoveSnackbar from '../hooks/useRemoveSnackbar';
import useSnackbarsToShow from '../hooks/useSnackbarsToShow';
import useSnackbarWasPresented from '../hooks/useSnackbarWasPresented';
import randomHexColorAlpha from '../utils/randomHexColor';
import DefaultSnackbarComponent from './SnackbarComponent';
export const SnackbarPresentationView = ({ Component = DefaultSnackbarComponent, isVisibleToUser = true, style, colorize, }) => {
    const snackbarWasPresented = useSnackbarWasPresented();
    const snackbarsToShow = useSnackbarsToShow();
    const removeSnackbar = useRemoveSnackbar();
    useEffect(() => {
        if (isVisibleToUser) {
            snackbarsToShow.forEach((snackbar) => snackbarWasPresented(snackbar.id));
        }
    }, [snackbarsToShow, snackbarWasPresented, isVisibleToUser]);
    return (React.createElement(View, { pointerEvents: 'box-none', style: [style, { backgroundColor: colorize ? randomHexColorAlpha() : undefined }] }, snackbarsToShow.map((i, index) => (React.createElement(Component, { doDismiss: removeSnackbar, key: i.id, id: i.id, snackbarConfig: i.snackbarConfig, index: index })))));
};
export default SnackbarPresentationView;
