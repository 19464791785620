import { useCallback, useState } from 'react';
export function useNullableState(initialValue = null) {
    const [value, setValueInternal] = useState(initialValue);
    const reset = useCallback(() => {
        setValueInternal(null);
    }, []);
    const setValue = useCallback((v) => {
        setValueInternal(v);
    }, []);
    return [value, setValue, reset];
}
export default useNullableState;
