import { PortalProvider } from '@gorhom/portal';
import React, { useEffect } from 'react';
import useSharedPortalAreaStore from '../hooks/useSharedPortalAreaStore';
export const SharedPortalAreaProvider = ({ children, insets }) => {
    const setDefaultInsets = useSharedPortalAreaStore((state) => state.setDefaultInsets);
    useEffect(() => {
        setDefaultInsets(Object.assign({ top: 0, bottom: 0, left: 0, right: 0 }, (insets !== null && insets !== void 0 ? insets : {})));
    }, [insets, setDefaultInsets]);
    return (React.createElement(PortalProvider, null, children));
};
export default SharedPortalAreaProvider;
