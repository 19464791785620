import { create } from 'zustand';
import getRandomID from '../utils/getRandomID';
export const DEFAULT_SNACKBAR_TIMOUT_MS = 5000;
export const DEFAULT_SNACKBARS_TO_SHOW_AT_SAME_TIME = 1;
let hasWarned = false;
const timeouts = new Map();
const useSnackbarStore = create((set) => ({
    defaultTimeoutMs: DEFAULT_SNACKBAR_TIMOUT_MS,
    setDefaultTimeoutMs: (timeout) => set(() => ({ defaultTimeoutMs: timeout !== null && timeout !== void 0 ? timeout : DEFAULT_SNACKBAR_TIMOUT_MS })),
    snackbarsToShowAtSameTime: DEFAULT_SNACKBARS_TO_SHOW_AT_SAME_TIME,
    setSnackbarsToShowAtSameTime: (value) => set((state) => {
        const snackbarsToShowAtSameTime = value !== null && value !== void 0 ? value : DEFAULT_SNACKBARS_TO_SHOW_AT_SAME_TIME;
        return {
            snackbarsToShow: state.snackbars.slice(0, snackbarsToShowAtSameTime),
            snackbarsToShowAtSameTime,
        };
    }),
    snackbars: [],
    snackbarsToShow: [],
    addSnackbar: (snackbarConfig) => set((state) => {
        const snackbars = [
            ...state.snackbars,
            {
                snackbarConfig: Object.assign(Object.assign({}, snackbarConfig), { type: snackbarConfig.type, data: snackbarConfig.data }),
                id: snackbarConfig.id || getRandomID(),
            },
        ];
        return {
            snackbars,
            snackbarsToShow: snackbars.slice(0, state.snackbarsToShowAtSameTime),
        };
    }),
    removeSnackbar: (id) => set((state) => {
        const snackbars = state.snackbars.filter((s) => s.id !== id);
        return {
            snackbars,
            snackbarsToShow: snackbars.slice(0, state.snackbarsToShowAtSameTime),
        };
    }),
    snackbarWasPresented: (id) => set((state) => {
        var _a, _b;
        const snackbar = state.snackbars.find((s) => s.id === id);
        if (!timeouts.has(id)) {
            (_b = snackbar === null || snackbar === void 0 ? void 0 : (_a = snackbar.snackbarConfig).onShow) === null || _b === void 0 ? void 0 : _b.call(_a);
            timeouts.set(id, setTimeout(() => {
                state.removeSnackbar(id);
                timeouts.delete(id);
            }, (snackbar === null || snackbar === void 0 ? void 0 : snackbar.snackbarConfig.timeout) || state.defaultTimeoutMs));
        }
        if (!hasWarned) {
            setImmediate(() => {
                if (timeouts.size === 0) {
                    console.warn('[@kingstinct/react] Snackbar added but not shown, make sure SnackbarView is present (or that you\'re calling snackbarWasPresented if rolling your own).');
                    hasWarned = true;
                }
            });
        }
        return {};
    }),
}));
export default useSnackbarStore;
